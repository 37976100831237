<style lang="scss">

.masonry-box {
  padding: 30px;
  margin-top: 50px;

  .cell-item {
    width: 100%;
    //margin-bottom: 20px;
    background: #ffffff;
    //border: 0px solid #F0F0F0;
    //border-radius: 0px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(3, 10, 24, 0.1);

    img {
      width: 100%;
      height: auto;
      min-height: 120px;
      display: block;
    }

    .item-body {
      font-size: 14px;
      color: #575150;
      line-height: 1.5;
      padding: 15px 20px 15px 20px;

      .item-desc {
        margin-top: 3px;
        position: relative;
        align-items: center;
        font-size: 12px;
        color: #aaa;
      }

      .item-buy {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6ebf5;
        padding-top: 12px;
        margin-top: 12px;

        .item-price {
          padding-top: 0.2em;


          .icon-price {
            transform: rotate(45deg);
          }

          .num-tag {
            font-size: 1.2em; font-weight: 500;
          }

          .unit-tag {
            font-size: 12px;
          }
        }

        .item-btns {

        }
      }
    }
  }
}

.order-qrcode {
  width: 200px;
}

@media only screen and (max-width: 981px) {
  .masonry-box {
    padding: 20px;

    .cell-item {
      //margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 641px) {

  .masonry-box {
    padding: 20px;
    margin-top: 10px;

    .cell-item {
      //margin-bottom: 12px;
    }
  }
}
</style>
<template>

  <div class="">
    <div class="masonry-box">
      <!--   <h3>Masonry: append endlessly</h3>-->
      <vue-masonry-wall :items="items" :options="options" @append="append">
        <template v-slot:default="{item}">

          <div class="cell-item">
            <img v-if="item.img" :src="item.img" alt="">
            <div class="item-body">
              {{ item.title }}
              <div class="item-desc">{{ item.desc }}</div>
              <div class="item-buy" v-if="item.is_pay==0">
                <div class="item-price"><i class="el-icon-discount icon-price num-tag"></i> <span class="num-tag">{{ item.price }}</span><span class="unit-tag">元</span></div>
                <div class="item-btns">
                  <el-button size="mini" icon="el-icon-shopping-bag-1" type="primary" plain @click="payment(item.id)">付费查看</el-button>
                </div>
              </div>
              <div class="item-buy" v-else>
                <div class="item-price"></div>
                <div class="item-btns">
                  <router-link :to=" '/'+category_path + '/'+ item.id">
                    <el-button size="mini" icon="el-icon-document" type="success" plain>查看</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

        </template>
      </vue-masonry-wall>
      <p v-show="loading" class="text-center">加载中...</p>
    </div>

    <el-dialog title="支付"
               :visible.sync="order_state"
               width="360px"
               :before-close="orderClose">
      <div><span v-html="order_msg"></span></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="orderClose">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="登录"
               :visible.sync="isLogging"
               width="360px"
               :before-close="loginClose">
      <div><span v-html="login_msg"></span></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="loginClose">取消</el-button>
      </span>
    </el-dialog>


  </div>
</template>
<script>
import VueMasonryWall from "vue-masonry-wall";
// import {Loading} from 'element-ui';

export default {
  name: "Masonry",
  props: {
    title: String,
    category_path: String,
    category_id: String,

  },
  components: {VueMasonryWall},
  data() {
    return {
      items: [],
      // itemsShow: [],
      // category_id: 2,// 分类
      totalPage: 1,
      curPage: 0,
      options: {
        width: 300,
        padding: {
          default: 12,
          1: 8,
          2: 7,
          3: 8
        },
      },
      loading: false,

      originData: [
        {
          img: "https://image.watsons.com.cn//upload/8a316140.png?w=377&h=451&x-oss-process=image/resize,w_1080",
          avatar: "https://img.xiaohongshu.com/avatar/5b7d198a7e6e15000155f7c9.jpg@80w_80h_90q_1e_1c_1x.jpg",
          title: "最近浴室新宠，日系神仙好物了解一下～",
          id: "953"
        }],

      order_qrcode: '',
      order_sn: '',
      order_state: false,
      order_msg: '',
      order_id: 0,

      wxQrcode: "",
      wxLoginCode: "",
      login_msg: '',
      login_state: false,
      isLogging: false
    };
  },

  watch: {
    // category_id: function (val) {
    //   console.log("type:" + val);
    //   this.reset();
    // },
  },
  // filters: {
  //   categoryFilter: function (dataItems, customType) {
  //     let showData = [];
  //     for (var i in dataItems) {
  //       if (customType == 'all' || customType == dataItems[i].pid) {
  //         showData.push(dataItems[i]);
  //       }
  //     }
  //     console.log(showData);
  //     return showData;
  //   }
  // },
  beforeMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  created: function () {
    // console.log(this.$route.params);
  },
  computed: {
    // itemWidth() {
    //   return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    // },
    // gutterWidth() {
    //   return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    // }
  },
  methods: {
    reset() {
      // console.log("reset");
      this.items = [];
      // this.curPage = 0;
      // this.getNextPage();
    },
    resizeHandler() {
      if (document.documentElement.clientWidth < 640) {
        this.options.width = 200;
      } else if (document.documentElement.clientWidth < 990) {
        this.options.width = 300;
      } else {
        this.options.width = 300;
      }
      // console.log("resizeHandler", this.col);
    },
    getNextPage() {

      let that = this;
      let token = window.localStorage.getItem("token");
      if (token === null) {
        token = '';
      }
      // if (token) {
      let nextPage = this.curPage + 1;
      if (nextPage <= this.totalPage) {
        this.loading = true;
        this.$http.post(
            '/index/auth/list',
            {
              // category_id: this.category_id,
              limit: 10,
              page: nextPage
            }, {
              headers: {
                'token': token
              }
            })
            .then(function (response) {
              // console.log(response);
              that.loading = false;

              that.totalPage = response.data.last_page;
              that.curPage = response.data.current_page;
              console.log(that.totalPage);

              for (let i = 0; i < response.data.data.length; i++) {
                let tmpItem = response.data.data[i];
                that.items.push(
                    {
                      img: tmpItem.image,
                      title: tmpItem.title,
                      desc: tmpItem.excerpt,
                      id: tmpItem.id,
                      pid: tmpItem.category_id,
                      is_pay: tmpItem.is_pay,
                      price: tmpItem.price
                    }
                )
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
      // } else {
      //   let that = this;
      //   that.$store.commit('updateToken', null);
      //   window.localStorage.removeItem("token");
      //   that.$router.push(`/members/login`);
      // }
    },
    payment(id) {
      // console.log(id);
      let _this = this;
      //  window.localStorage.setItem("token", token);
      let token = window.localStorage.getItem("token");

      let title = '';
      let price = '';
      for (var i in this.items) {
        if (this.items[i].id === id) {
          title = this.items[i].title;
          price = this.items[i].price;
          break;
        }
      }
      this.order_id = id;
      if (token) {
        if (this.isPaying) {
          return;
        }
        this.isPaying = true;
        this.$http.post(
            '/index/auth/pay',
            {
              id: id
            },
            {headers: {'token': token}}
        )
            .then(function (res) {
              console.log(res);
              if (res.code === 0) {

                let reData = res.data;

                _this.order_qrcode = reData.qrcode;
                _this.order_sn = reData.order_sn;

                _this.order_msg = '内容：<strong>' + title + '</strong><br>费用：<strong>' + price + '</strong>元<br>请使用微信扫描该二维码完成支付<br><p class="text-center"><img src="' + _this.order_qrcode + '" class="order-qrcode" alt=""></p>';

                _this.order_state = true;
                setTimeout(_this.checkPayStatus, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
              _this.isPaying = false;
            });
      } else {
        if (this.isLogging) {
          return;
        }
        _this.isLogging = true;
        this.$http.post(
            '/index/auth/get_qr_code',
            {},
            {
              headers: {}
            })
            .then(function (response) {
              console.log(response);
              if (response.code === 0) {
                let reData = response.data;

                _this.login_state = true;
                _this.wxQrcode = reData.url;
                _this.wxLoginCode = reData.code;
                console.log(_this.wxQrcode);

                _this.login_msg = '<p class="text-center">请使用微信扫描该二维码登录<br><br> <img src="' + reData.url + '" class="order-qrcode" alt=""></p>';

                setTimeout(_this.checkLogin, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
              _this.isLogging = false;
            });
      }
      //   _this.$router.push(`/members/login`);
    },
    checkPayStatus() {
      // console.log(id);
      if (!this.isPaying) {
        return false;
      }
      let that = this;
      //  window.localStorage.setItem("token", token);
      let token = window.localStorage.getItem("token");

      if (token) {
        this.$http.post(
            '/index/auth/check_pay_status',
            {
              order_sn: that.order_sn
            },
            {headers: {'token': token}})
            .then(function (res) {
              console.log(res);
              if (res.code === 0) {
                let reData = res.data;
                if (reData.status === 1) {
                  that.orderClose();
                  that.$message({type: 'success', message: '支付成功'});

                  for (var i in that.items) {
                    if (that.items[i].id == that.order_id) {
                      that.items[i].is_pay = true;
                      setTimeout(function () {
                        that.$router.push(`/members/${that.order_id}`);
                      }, 800)
                      break;
                    }
                  }

                }
              }
              if (that.order_state) {
                setTimeout(that.checkPayStatus, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
              if (that.order_state) {
                setTimeout(that.checkPayStatus, 2000);
              }
            });
      }
    },
    checkLogin() {
      let _this = this;
      if (!_this.isLogging) {
        return false;
      }
      this.$http.post(
          '/index/auth/check_login',
          {
            code: _this.wxLoginCode
          },
          {
            headers: {}
          })
          .then(function (res) {
            console.log(res);
            let token = res.data.token;
            if (res.code === 0) {
              if (res.data.status === 1) {
                _this.isLogging = false;

                _this.$store.commit('updateToken', token);
                window.localStorage.setItem("token", token);
                // that.$router.push("/members/list");
                console.log("check Logined");

                _this.loginClose();
                if (_this.order_id !== 0) {
                  location.href = './?logined#/members/list';
                }

              } else {
                setTimeout(_this.checkLogin, 2000);
              }
            } else {
              setTimeout(_this.checkLogin, 2000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });

    },
    orderClose() {
      console.log('orderClose');
      this.order_qrcode = '';
      this.order_sn = '';
      this.order_state = false;
      this.isPaying = false;
      // this.order_id = 0;
    },
    loginClose() {
      console.log('loginClose');
      this.wxQrcode = '';
      this.wxLoginCode = '';
      this.login_msg = '';
      this.login_state = false;
      this.isLogging = false;
    },
    append() {
      // console.log("append");
      this.getNextPage();
    }
  },
  mounted() {
    // this.getNextPage();
  },
  destroyed() {
    this.loginClose();
    this.orderClose();
  }
};
</script>
